var originalPrice = 129900;
var discountPrice = 129900;
var freight = 0;
var userId;
var tid = null;
var itemId = null;
var defaultItemId = "modoofirst";
var defaultTrackId = "default_tid";
var ticket;
var local = window.localStorage;

// var urlHeader = 'https://test.mall.meng-dong.com/access?';
var urlHeader = '/access';

function getPriceInfo(forceItem) {
  var selectedItemId = forceItem === undefined? getItemId() : forceItem;
  console.log(selectedItemId);
  var webReqHeader = initWebReqHeader();
  var requestData = {
    cmd: 100,
    WebReqHeader: webReqHeader,
    request: {
      itemId: selectedItemId,
      trackId: findTrackId(),
    }
  }
  $.ajax({
    url: urlHeader,
    type: 'post',
    dataType: "json",
    data: JSON.stringify(requestData),
    success: function(data) {
      if (data.ret == 0) {
        var responseData = $.parseJSON(data.response);
        originalPrice = responseData.price;
        discountPrice = responseData.discountPrice;
        freight = responseData.freight;
        showPrice();
      } else if (data.ret == -1) {
        alert('网络失败');
      }
    },
  });
}

function initWebReqHeader() {
  var seq = parseInt(local.getItem('seq')) | 0;
  seq += 1;
  localStorage.setItem('seq', seq);
  var WebReqHeader = {
    uuid: getUuid(),
    ua: navigator.userAgent,
    ticket: local.getItem('ticket'),
    seq: seq,
  }
  if (local.getItem('ticket') == null) {
    delete WebReqHeader.ticket;
  }
  //console.log(WebReqHeader);
  return WebReqHeader;
}

function isLogin() {
  return isExist('ticket') && isExpired();
}

function isExist(item) {
  return window.localStorage.getItem(item) != null;
}

function isExpired() {
  if (local.getItem('validTime') == null) {
    return false;
  }
  var currentTime = (new Date()).getTime();
  return currentTime < parseInt(local.getItem('validTime'));
}

function getURLParameter(name) {
  return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [, ""])[1].replace(/\+/g, '%20')) || null
}

function setTid() {
  tid = getURLParameter('tid') == null ? defaultTrackId : getURLParameter('tid');
}

function setItemId() {
  itemId = getURLParameter('itemId') == null ? defaultItemId : getURLParameter('itemId');
}

function appendUrlItemIdParam() {
  if (itemId == undefined || itemId == null || itemId == defaultItemId || itemId == "") {
    return "";
  }
  return "&itemId=" + itemId;
}

function appendUrlTidParam() {
  if (tid == undefined || tid == defaultTrackId || tid == "") {
    return "";
  }
  return "&tid=" + tid;
}

function isWechatBrowser() {
  var ua = window.navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true;
  } else {
    return false;
  }
}

function getAuthUrl(type) {
  var redirectURI = encodeURIComponent("https://zc.meng-dong.com/ma");
  var targetURL;
  if (type == undefined || type == "buy") {
    targetURL = 'https://' + window.location.host + '/order/buy.html?' + appendUrlTidParam() + appendUrlItemIdParam();
  } else {
    targetURL = 'https://' + window.location.host + '/order/rental.html?' + appendUrlTidParam() + appendUrlItemIdParam();
  }
  var targetURLEncode = encodeURIComponent(targetURL);
  var url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx1ec170fef9742ca8&redirect_uri=" + redirectURI + "&response_type=code&scope=snsapi_base&state=" + targetURLEncode + "#wechat_redirect";
  return url;
}

function getItemId() {
  return getURLParameter('itemId') == null ? defaultItemId : getURLParameter('itemId');
}

function findTrackId() {
  return getURLParameter('tid') == null ? defaultTrackId : getURLParameter('tid');
}

function getUuid() {
  if (local.getItem('uuid') != null) {
    return local.getItem('uuid');
  } else {
    var timeNow = new Date();
    local.setItem('uuid', timeNow.getTime().toString());
    return timeNow.getTime().toString();
  }
}
